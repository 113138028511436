import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth-guard.service';
import { NoAuthGuard } from './services/auth/noAuth.guard';
import { HomeComponent } from './modules/user/home/home.component';
import { ClassroomGuard } from './services/auth/classroom-guard.service';
import { ClasstimeGuard } from './services/auth/classtime-guard.service';

const routes: Routes = [
  // Non-authenticated routes
  { path: 'login', canActivate: [NoAuthGuard], loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule) },
  { path: 'forgot-password', canActivate: [NoAuthGuard], loadChildren: () => import('./modules/auth/forgot-passowrd/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'token', canActivate: [NoAuthGuard], loadChildren: () => import('./modules/auth/token/token.module').then(m => m.TokenModule) },
  { path: 'new-password', canActivate: [NoAuthGuard], loadChildren: () => import('./modules/auth/new-password/new-password.module').then(m => m.NewPasswordModule) },
  { path: 'video/:classId', canActivate: [ClassroomGuard, ClasstimeGuard], loadChildren: () => import('./modules/classroom/classroom.module').then(m => m.ClassroomModule) },
  { path: 'waitingroom/:classId', canActivate: [ClassroomGuard], loadChildren: () => import('./modules/classroom/waitingroom/waitingroom.module').then(m => m.WaitingroomModule) },
  {
    path: 'first-access',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/first-access/first-access.module').then(m => m.FirstAccessModule),
  },
  // Authenticated routes
  {
    path: 'home',
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./modules/user/home/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'profile', loadChildren: () => import('./modules/user/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'classes', loadChildren: () => import('./modules/user/home/classes/classes.module').then(m => m.ClassesModule) },
      { path: 'documents', loadChildren: () => import('./modules/user/home/documents/documents.module').then(m => m.DocumentsModule) },
      //{ path: 'tickets', loadChildren: () => import('./modules/user/home/tickets/tickets.module').then(m => m.TicketsModule) },
      //{ path: 'tickets/:id', loadChildren: () => import('./modules/user/home/tickets/components/edit-ticket/edit-ticket.module').then(m => m.EditTicketModule) },
    ]
  },

  // Default route
  { path: '', redirectTo: 'home/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'home/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
